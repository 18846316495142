import React from "react";
import { Construction, Sparkles } from "lucide-react";

const UnderConstruction = () => {
  return (
    <div className="min-h-screen min-w-full bg-gradient-to-br from-indigo-50 via-blue-50 to-purple-50 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl text-center ">
        {/* Logo/Title Section */}
        <div className="mb-8 relative inline-block">
          <h1 className="text-6xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Rendezvuous
          </h1>
          <div className="absolute -top-4 -right-4">
            <Sparkles className="w-6 h-6 text-blue-400 animate-pulse" />
          </div>
        </div>

        {/* Decorative Line */}
        <div className="flex items-center justify-center mb-12 space-x-4">
          <div className="h-0.5 w-12 bg-gradient-to-r from-transparent via-blue-400 to-transparent"></div>
          <Construction className="w-6 h-6 text-blue-500 animate-bounce" />
          <div className="h-0.5 w-12 bg-gradient-to-r from-transparent via-blue-400 to-transparent"></div>
        </div>

        {/* Main Content */}
        <div className="space-y-6 bg-white/40 backdrop-blur-sm rounded-2xl p-8 shadow-xl mx-4">
          <h2 className="text-2xl font-semibold text-gray-800">
            Something Amazing Is Coming
          </h2>
          <p className="text-lg text-gray-600">
            We're crafting something special just for you.
          </p>
          <p className="text-gray-500">
            Our team is working hard to bring you an exceptional experience.
          </p>

          {/* Coming Soon Badge */}
          <div className="inline-block px-4 py-2 bg-blue-100 text-blue-700 rounded-full font-medium mt-4">
            Coming Soon
          </div>
        </div>

        {/* Footer */}
        <div className="mt-12 text-sm text-gray-500">
          © 2024 Rendezvuous. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
