import { Helmet, HelmetProvider } from "react-helmet-async";
import UnderConstruction from "./pages/UnderConstruction";

// In your App.tsx or main component:
function App() {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Rendezvuous app</title>
          {/* You can also add other head elements like meta tags */}
          <meta name="description" content="Rendezvuous app - Coming soon" />
        </Helmet>
        <UnderConstruction />
      </div>
    </HelmetProvider>
  );
}

export default App;
